function toggleScript() {

    var Ww              = $(window).width();
        Wh              = $(window).height();
        dpelems         = "a[data-onhover='dropdown']";
        dpclass         = ".navbar-collapse .nav > .dropdown";

    if (Ww >= 1024) {                       // Do hover effect only for large
        
        $(dpelems).dropdownHover();

    }else if(Ww < 991){                     // small always open
        
        $(dpclass).addClass("open");
        $(dpclass).on("mouseleave", function(){
          $(this).addClass("open");
        });
    
    }else{                                  // unset hover dropdown for tablets
        
        //$(dpelems).attr("href","#");
        //$(dpelems).attr("data-toggle","dropdown");

    };

}

$(document).ready(function(){       toggleScript();         });
$( window ).resize(function() {     toggleScript();         });
