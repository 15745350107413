/*============================================================
    VARIABLES
============================================================*/
  var Window = $(window),                                               /* get window element */
      Document = $(document),                                           /* get document element */
      WindowWidth = Window.width(),                                     /* get window width */
      DocumentWidth = Document.width(),                                 /* get document width */
      WindowHeight = Window.height(),                                   /* get window height */
      DocumentHeight = Document.height()                                /* get document height */
      ;
/*============================================================
    FUNCTIONS
============================================================*/
  function exist(cssSelector) { return $(cssSelector).length > 0; }
  function toggleHeaderStyle() {
    $header = $('.navbar-default');
    $breakpoint = 1200;
    if ($(window).scrollTop() <= 0 && $(window).width() >= $breakpoint && $('.header-blank-space').length == 0){
  	  $header
        .removeClass("active");
    }else{
      if ($(window).width() <= $breakpoint && $('.header-blank-space').length == 0) {
        $('.wrapper')
          .css({"margin-top":"85px"});
      }
      $header
        .addClass("active");
    };
  }
  function fadeoutPreloader() {
    $('.slick-slider').on('init', function(event, slick){
      //$('.preloader').height($('.preloader').find('.slick-slide').height());
      $('.preloader').css({"overflow":"visible"});
      $('.preloader-status').fadeOut(1000);
    });
  }
  function enableSlider() {
    /* Check Element */
      if ($('.single-item').length > 0) {    
        $('.single-item').slick();
      }
  }
  function contact_maps_sm_height_cols() {
    if ($('body').width()>=768 && $('body').width()<992) {
      var imgHeight = "350px";
      $('.people.single,.people.triple')
        .find('.col-sm-6')
        .height(imgHeight);
    }
  }
  function masonry() {
    $('.masonry-container').masonry({
      columnWidth: '.item',
      itemSelector: '.item'
    }); 
  }
  function activefirstaccordion() {
    $('.collapse-wrapper')
      .first()
      .addClass("active")
      .find('.collapse-element')
      .addClass("in");
  }
  function recruitmentFormSizeFields() {
    $(".form-control").each(function(index){
      var labelElement = $(this).find('label'),
          inputElement = $(this).find("input, select, input[type='file'], .selectize"),
          borderWidth = 1,
          horizontalPadding = 10 + (borderWidth * 2),                                           /* horizontal padding is 12px (defined in css) */
          formControlWidth = $(this).width() - (horizontalPadding * 2), 
          labelSize = labelElement.width(),
          restOfWidth = formControlWidth - labelSize;
      if ($(this).find("select").length > 0) {
        inputElement.width(restOfWidth - 20);                               /* remove counted background icon padding */
      }else{
        inputElement.width(restOfWidth);
      }
    });
  }
  function datepickers() {
    $('.datepicker-current-as-max').datepicker({
      maxDate: new Date(),
      dateFormat: "yy-mm-dd",
      changeYear: true,
      yearRange: "1900:+nn",
      changeMonth: true,
      //changeRange: "1900:+nn"
    }).prop("readonly","readonly");
  }
  function selectizes() {
    $('.selectize').selectize({
      plugins: ['remove_button'],
      create: false,
      sortField: 'name',
      valueField: 'value',
      mode: 'multi',
      labelField: 'name',
      searchField: ['name', 'value'],
    });
    if ($(window).width()>=992) {
      $('.selectize-simplified').selectize({
        create: false
      });
    }
  }

  function extensible() {
    
    if ($('.extensible-class').length > 0) {
      $('.extensible-class').siblings("p").css({
        "min-height":"30px",
        "height":"auto",
        "transition":"max-height 1s ease-in-out",
        "max-height":"70px"
      });
      $('.ec1').click(function(){
        $(this).addClass("hidden");
        $(this).siblings(".ec2").removeClass("hidden");
        $(this).siblings("p").css({"max-height":"70px"});
      });
      $('.ec2').click(function(){
        $(this).addClass("hidden");
        $(this).siblings(".ec1").removeClass("hidden");
        $(this).siblings("p").css({"max-height":"250px"});
      });
    }
  }
/*============================================================
    ACCORDION
============================================================*/
  function setAlreadyCollapsed() {
    $('.collapse-element').each(function(i, el) {
      $(el).addClass('collapse');
      if ($(el).hasClass('collapsing')) {
        $(el).removeClass('collapsing');
      };
      $(el).removeClass('in');
      $(el).attr('aria-expanded', 'false');
    });
  }
  function accordions(collapsed) {
    $('.collapse-wrapper .btn').click(function(e) {
      e.preventDefault();
      $(this).siblings('.collapse-element').collapse('toggle');
      $(this).parent().toggleClass("active");
    });
    /* SET ALREADY COLLAPSED if true (nice for other resolutions) */
    if (collapsed) { setAlreadyCollapsed(); }
  }
/*============================================================
    READY EVENTS
============================================================*/
  $(document).ready(function(){
    extensible();
    toggleHeaderStyle();
    accordions(true);
    masonry();
    enableSlider();
    activefirstaccordion();
    recruitmentFormSizeFields();
    datepickers();
    selectizes();
    //contact_maps_sm_height_cols();
  });
/*============================================================
    RESIZE EVENT
============================================================*/
  $(window).resize(function(){ toggleHeaderStyle(); });
  /*============================================================
    SCROLL BUTTON STICKY LOGIC
  ============================================================*/ 
    var sz = $('.footer').height();
    if (exist('.scrollup')) { /* Check Element */
      $('.scrollup').hide(); 
      Window.scroll(function(){
        if (Window.scrollTop()>=100) {     $('.scrollup').fadeIn();    }
        else {                             $('.scrollup').fadeOut();   }
        if ( (Window.scrollTop() + Window.height()) >= Document.height()-sz) {
          $('.scrollup').addClass("sticky");
        } else {
          $('.scrollup').removeClass("sticky");
        }
      });
    }
/*============================================================
    SCROLL EVENT
============================================================*/
  $(document).scroll(function(){ toggleHeaderStyle(); });
/*============================================================
    OTHER EVENT
============================================================*/
  fadeoutPreloader();